import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate {

    canActivate (): boolean {
      if (localStorage.getItem('role') === 'ROLE_ADMIN') return true;
      else return false;
    }
}
