<div class="row">
  <div class="col-xxxl-12 col-xxl-12 col-lg-12 col-md-12">
    <ngx-security-cameras></ngx-security-cameras>
  </div>
</div>
<nb-card>
    <nb-card-header>Trial Sites</nb-card-header>
    <nb-card-body>
      <div echarts [options]="options" class="echarts"></div>
    </nb-card-body>
</nb-card>