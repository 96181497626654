import { Component, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { NbThemeService } from '@nebular/theme';
import { registerMap } from 'echarts';

@Component({
  selector: 'ngx-dashboard',
  styleUrls: ['./dashboard.component.scss'],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnDestroy {

  latlong: any = {};
  mapData: any[];
  max = -Infinity;
  min = Infinity;
  options: any;
  message: any;

  bubbleTheme: any;
  geoColors: any[];

  private alive = true;

  constructor(private theme: NbThemeService,
              private http: HttpClient) {

    combineLatest([
      this.http.get('assets/map/world.json'),
      this.theme.getJsTheme(),
    ])
      .pipe(takeWhile(() => this.alive))
      .subscribe(([map, config]: [any, any]) => {

        registerMap('world', map);

        const colors = config.variables;
        this.bubbleTheme = config.variables.bubbleMap;
        this.geoColors = [colors.primary, colors.info, colors.success, colors.warning, colors.danger];

        this.latlong = {
          'Greece': { 'latitude': 37.98, 'longitude': 23.73 },
          'Turkey': { 'latitude': 39.92, 'longitude': 32.85 },
          'Spain': { 'latitude': 37.72, 'longitude': -2.14 },
          'Portugal': { 'latitude': 38.72, 'longitude': -9.14 },
          'Finland': { 'latitude': 60.18, 'longitude': 24.93 },
          'France': { 'latitude': 48.86, 'longitude': 2.33 },
          'Germany': { 'latitude': 52.52, 'longitude': 13.40 },
          'Netherlands': { 'latitude': 52.37, 'longitude': 4.89 },
          'China': { 'latitude': 39.90, 'longitude': 116.41 },
          'South Korea': { 'latitude': 37.57, 'longitude': 126.97 },
        };

        this.mapData = [
          { 'code': 'Greece', 'name': 'Greece', 'value': 1, 'color': '#de4d60' },
          { 'code': 'Turkey', 'name': 'Turkey', 'value': 1, 'color': '#de4d60' },
          { 'code': 'Spain', 'name': 'Spain', 'value': 1, 'color': '#f49e4c' },
          { 'code': 'Portugal', 'name': 'Portugal', 'value': 1, 'color': '#f49e4c' },
          { 'code': 'Finland', 'name': 'Finland', 'value': 1, 'color': '#de4d60' },
          { 'code': 'France', 'name': 'France', 'value': 1, 'color': '#2d728f' },
          { 'code': 'Germany', 'name': 'Germany', 'value': 1, 'color': '#de4d60' },
          { 'code': 'Netherlands', 'name': 'Netherlands', 'value': 1, 'color': '#cfdf87' },
          { 'code': 'China', 'name': 'China', 'value': 1, 'color': '#784c67' },
          { 'code': 'South Korea', 'name': 'South Korea', 'value': 1, 'color': '#784c67' },
        ];

        this.mapData.forEach((itemOpt) => {
          if (itemOpt.value > this.max) {
            this.max = itemOpt.value;
          }
          if (itemOpt.value < this.min) {
            this.min = itemOpt.value;
          }
        });

        this.options = {
          tooltip: {
            trigger: 'item',
            formatter: params => {
              return `${params.name}: ${params.value[2]}`;
            },
          },
          visualMap: {
            show: false,
            min: 30,
            max: this.max,
            inRange: {
              symbolSize: [10, 10],
            },
          },
          geo: {
            name: 'Trial Sites',
            type: 'map',
            map: 'world',
            roam: true,
            label: {
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                areaColor: this.bubbleTheme.areaColor,
                borderColor: this.bubbleTheme.areaBorderColor,
              },
              emphasis: {
                areaColor: this.bubbleTheme.areaHoverColor,
              },
            },
            zoom: 1.1,
          },
          series: [
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              data: this.mapData.map(itemOpt => {
                return {
                  name: itemOpt.name,
                  value: [
                    this.latlong[itemOpt.code].longitude,
                    this.latlong[itemOpt.code].latitude,
                    itemOpt.value,
                  ],
                  itemStyle: {
                    normal: {
                      color: itemOpt.color,
                    },
                  },
                };
              }),
            },
          ],
        };
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private getRandomGeoColor() {
    const index = Math.round(Math.random() * this.geoColors.length);
    return this.geoColors[index];
  }
}
