import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">Created by <b><a href="https://5g-mobix.com" target="_blank">AKKA Research</a></b> 2020</span>
  `,
})
export class FooterComponent {
}
