import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuard } from './services/authorization/auth-guard.service';


const routes: Routes = [
  { path: 'pages',
   canActivate: [AuthGuard], 
   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
  {
    path: 'auth',
    loadChildren: 'app/auth/auth.module#NgxAuthModule',
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
