import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  auth2StrategyOptions,
  NbAuthResult,
  NbAuthSimpleToken,
  NbAuthService,
  NbTokenService,
  NbAuthToken,
} from '@nebular/auth';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { of } from "rxjs";

export interface UserData {
  username: string;
  password: string;
}

export interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

export interface AuthConfig {
  apiAddress: string;
}

@Injectable()
export class authService extends NbAuthService {


    authenticate(strategyName: string, data?: any): Observable<NbAuthResult> {
        return this.getStrategy(strategyName).authenticate(data)
          .pipe(
            switchMap((result: NbAuthResult) => {
              return this.processResult(result);
            }),
          );
      }
    public processResult(result: NbAuthResult) {
      this.tokenService.set(result.getToken());
        if (result.isSuccess() && result.getToken()) {
          return this.tokenService.set(result.getToken())
        }
    
        return of(result);
      }
      requestPassword(strategyName: string, data?: any): Observable<NbAuthResult>  {
        var pwd = btoa(localStorage.username+":"+data.password);
        return this.getStrategy('email').resetPassword(pwd);
      }
}