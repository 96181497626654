import { Injectable } from '@angular/core';


@Injectable()
export class Configuration {

 constructor() {}

 getmessage(code: string): string {

        switch (code) {
            case '401':
                return 'Unauthorized : The request requires user authentication';
            case '403':
                return 'Unauthorized : The request requires user authorization';
            case '500':
                return 'Internal Server Erro : The HTTP server encountered an unexpected condition';
            case '406':
                return 'The file doesn\'t exist ';
            default:
                return null;
             }
    }
}
