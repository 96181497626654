import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxAuthRoutingModule } from './auth-routing.module';
import { getDeepFromObject, NbAuthJWTToken, NbAuthModule, NbAuthOAuth2Token, NbAuthSimpleToken, NbOAuth2AuthStrategy, nbOptionsFactory, NbPasswordAuthStrategy, NB_AUTH_INTERCEPTOR_HEADER, NB_AUTH_OPTIONS, NB_AUTH_USER_OPTIONS } from '@nebular/auth';
import {
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule
} from '@nebular/theme';
import { NgxLoginComponent } from './login/login.component';
import { environment as env } from '../../environments/environment';
import { authService } from './auth.service';
import { NgxResetPasswordComponent } from './reset-password/reset-password.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NgxAuthRoutingModule,
    NbLayoutModule,
    NB_MODULES

  ],
  declarations: [
    NgxLoginComponent,
    NgxResetPasswordComponent,
    // ... here goes our new components
  ],
  providers: [
  ]
})
export class NgxAuthModule {
}