import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { NbAuthResult, NbAuthService, NbResetPasswordComponent, NB_AUTH_OPTIONS } from "@nebular/auth";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { authService } from "../auth.service";
import { environment as env, environment } from '../../../environments/environment';
import { StorageToken } from "../../services/authorization/token-storage";

@Component({
    selector: 'ngx-resetPassword',
    templateUrl: './reset-password.component.html',
  })
  export class NgxResetPasswordComponent extends NbResetPasswordComponent {
      constructor( protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) options: {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
        protected authService: authService,
        protected http: HttpClient,
        private storageToken: StorageToken,
        ){
          super(service, options, cd, router);
      }
      resetPass() {
        this.errors = this.messages = [];
        this.submitted = true;
        var pwd = btoa(localStorage.username+":"+ this.user.password);
        var tokenKey = this.getConfigValue('resetPass.resetPasswordTokenKey');
       // data[tokenKey] = this.route.snapshot.queryParams[tokenKey];
        var method = 'put';
        var url =  env.baseApiUrl +  '/update-password';
        return this.http.request(method, url, { body: pwd, observe: 'response',  headers: this.storageToken.GetHeader() })
            .pipe(map(function (res) {
            if (this.getConfigValue('resetPass.alwaysFail')) {
                throw this.createFailResponse();
            }
            return res;
        }), map(function (res) {
            return new NbAuthResult(true, res, this.getConfigValue('resetPass.redirect.success'), [], this.getConfigValue('messages.getter')('resetPass', res));
        }), catchError(function (res) {
            var errors = [];
            if (res instanceof HttpErrorResponse) {
                errors = this.getConfigValue('errors.getter')('resetPass', res);
            }
            else {
                errors.push('Something went wrong.');
            }
            return of(new NbAuthResult(false, res, this.getConfigValue('resetPass.redirect.failure'), errors));
        }))
        .subscribe(
          (result: NbAuthResult) => {
          this.submitted = false;
          if (result.isSuccess()) {
            this.messages = result.getMessages();
          } else {
            this.errors = result.getErrors();
          }
    
          const redirect = result.getRedirect();
          if (redirect) {
            setTimeout(() => {
              return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);
          }
          this.cd.detectChanges();
        },
        err => {
          this.submitted = false;
          if (err instanceof HttpErrorResponse) {
            this.messages = err.error;
            localStorage.errors = err;
            this.errors = [err.error.message];
          }
          else {
            localStorage.errors = err;
          }
          return of(new NbAuthResult(false, err, this.getConfigValue('login.redirect.failure'), err));
        }
        );
      }
  
  }