import { Role } from './role';
export class User {

  id: number;
  username: string;
  fullName: string;
  email: string;
  password: string;
  role: Role;

  constructor(username: string, fullName: string, email: string, role: Role) {

    this.username = username;
    this.fullName = fullName;
    this.email = email;
    this.role = role;
  }

}
