import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NbAuthComponent, NbResetPasswordComponent } from '@nebular/auth';  // <---
import { NgxResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxLoginComponent } from './login/login.component';
export const routes: Routes = [
    {
      path: '',
      component: NbAuthComponent,
      children: [
        {
          path: 'login',
          component: NgxLoginComponent, // <---
        },
        {
          path: 'update-password',
          component: NgxResetPasswordComponent,
        },
      ],
    },
  ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {
}