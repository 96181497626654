import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { nbAuthCreateToken, NbAuthJWTToken, NbAuthOAuth2Token, NbAuthResult, NbAuthService, NbAuthSimpleToken, NbAuthToken, NbLoginComponent, NbTokenService, NbTokenStorage, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { authService } from '../auth.service';
import { environment as env, environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class NgxLoginComponent extends NbLoginComponent {
  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) options: {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected http: HttpClient,
    protected authService: authService,
    protected tokenService: NbTokenService,
    protected tokenStorage: NbTokenStorage
  ) {
    super(service, options, cd, router);
  }
  login(): any {
    this.errors = [];
    this.messages = [];
    this.submitted = true;
    // localStorage.username= da.ta.username;
    var credentials = btoa(this.user.user + ":" + this.user.password);
    const _headers = new HttpHeaders;
    const headers = _headers.append('Credentials', credentials)
      .append('Access-Control-Allow-Origin', '*')
      .append('Access-Control-Allow-Credentials', 'true')
      .append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, HEAD')
      .append('Access-Control-Allow-Headers', 'Authorization, Origin, X-Requested-With, Content-Type, Accept');
    var method = 'POST';
    var url = environment.baseApiUrl + '/login';
    return this.http.request('post', url, { headers: headers, observe: 'response' })
      .subscribe(
        res => {
          this.submitted = false;
          const tokenStored = nbAuthCreateToken<NbAuthJWTToken>(NbAuthJWTToken, res.body['token'], 'token');
          const token = new NbAuthResult(
            true,
            res,
            '/pages/dashboard',
            [],
            'message',
            tokenStored
          );
          this.authService.processResult(token);
          localStorage.setItem('token', tokenStored.getValue());
          localStorage.setItem('username', tokenStored.getPayload()['sub'])
          localStorage.setItem('role', tokenStored.getPayload()['auth'][0]['authority'])
          if (token.isSuccess()) {
            this.messages =  ['You have been successfully logged in.'];
          } 
          const redirect = token.getRedirect();
          if (redirect) {
            this.router.navigate(['/pages/dashboard']);
          }
          this.cd.markForCheck();
        },
        err => {
          this.submitted = false;
          if (err instanceof HttpErrorResponse) {
            this.messages = err.error;
            //   errors = this.getConfigValue('errors.getter')('login', err);
            localStorage.errors = err;
            this.errors = [err.error.message];
          }
          else {
            localStorage.errors = err;
          }
          return of(new NbAuthResult(false, err, this.getConfigValue('login.redirect.failure'), err));
        })


  }
  validateToken = function (module) {
    return map(function (res) {
      var token = this.getConfigValue('token.getter')(module, res);
      if (!token) {
        var key = this.getConfigValue('token.key');
        console.warn("NbEmailPassAuthProvider:\n                          Token is not provided under '" + key + "' key\n                          with getter '" + this.getConfigValue('token.getter') + "', check your auth configuration.");
        throw new Error('Could not extract token from the response.');
      }
      return res;
    });
  };



}