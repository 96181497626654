import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { Camera, SecurityCamerasData } from '../data/security-cameras';

@Injectable()
export class SecurityCamerasService extends SecurityCamerasData {

  private cameras: Camera[] = [
    {
      title: 'Highway',
      source: 'assets/images/visuel11-5g-mobilite.jpg',
    },
    {
      title: 'Connected Car',
      source: 'assets/images/connected-car.jpg',
    },
    {
      title: 'Border Crossing',
      source: 'assets/images/border-crossing.jpg',
    },
    {
      title: 'Platooning',
      source: 'assets/images/truck-platooning.jpg',
    },
  ];

  getCamerasData(): Observable<Camera[]> {
    return observableOf(this.cameras);
  }
}
