<div class="header-container"
     [class.left]="position === 'normal'"
     [class.right]="position === 'inverse'">
  <div class="logo-containter">
    <a (click)="toggleSidebar()" href="#" class="navigation"><i class="nb-menu"></i></a>
    <div class="logo" (click)="goToHome()"><img src="assets/images/ERT-032-18-logo-ok_rgb-768x218-50.png"/></div>
  </div>
</div>

<nb-actions
  size="medium"
  class="header-container"
  [class.right]="position === 'normal'"
  [class.left]="position === 'inverse'">
  <nb-action *nbIsGranted="['view', 'user']" >
    <nb-user [nbContextMenu]="userMenu" [name]="user?.name" ></nb-user>
    <div class="user-picture background">
        <i class="far fa-user"></i>
    </div>
    <div class="user">{{ username }}</div>
  </nb-action>
  <nb-action>
      <div class="swagger">
          <a href="{{url}}" target="_blank"><img  src="assets/images/swagger.png"/></a>
      </div>
  </nb-action>
</nb-actions>
