import { Injectable } from '@angular/core';
import { HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable()
export class StorageToken {


    constructor() {}

    GetHeader():  HttpHeaders {
        let JWT = 'Bearer ' + localStorage.getItem('token');
        const _headers = new HttpHeaders;
        const headers  = _headers.append('Authorization', JWT);
        return headers;
    }

    getDownloadHeader():  HttpHeaders {
        let JWT = 'Bearer ' + localStorage.getItem('token');
        const _headers = new HttpHeaders;
        const headers  = _headers.append('Authorization', JWT)
                                .append('Content-Type', 'application/octet-stream');
        return headers;
    }
}
