import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import { User } from '../../dto/user-list';
import { StorageToken } from '../../services/authorization/token-storage';


@Injectable()
export class UserService {

 apiUser = env.baseApiUrl + '/users';
 body: string;
 json: string;

  constructor(private http: HttpClient, private storageToken: StorageToken) { }

  findAll(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUser, {headers: this.storageToken.GetHeader()});
  }

  /*findById(username):Observable<UserList>{
      return this.http.get<UserList>(`${this.apiUser}/${username}`,{headers: this.storageToken.GetHeader()});
  }*/

  createAccount(json) {
    let user = new User(json.username, json.fullName , json.email, json.role);
    return this.http.post(this.apiUser, user, {headers: this.storageToken.GetHeader()}).subscribe(val => val);
  }

  delete(user) {
    return this.http.delete(`${this.apiUser}/${user.username}`, {headers: this.storageToken.GetHeader()}).subscribe(val => val);
  }

  update(user) {
    return this.http.put(`${this.apiUser}/${user.id}`, user, {headers: this.storageToken.GetHeader()}).subscribe(val => val);
  }
}
