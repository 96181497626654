import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NbAuthService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: NbAuthService) {}

    isAuthenticated(): boolean {
        if (!localStorage.token) return false;
        else return true;
    }
    canActivate (): boolean {
        const isAuth = this.isAuthenticated();
        if (!isAuth) {
            window.location.href = '#/auth/login';
        }
        return isAuth;
    }
}
